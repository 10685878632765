<template>
<div>
    <s-crud  noFull :height="$vuetify.breakpoint.xs ? 'auto' : '200'" :config="configCrud" :filter="filter" sortable searchInput title="Aprobación de OC/OS" :single-row="this.isMobile" @rowSelected="rowSelected($event)" ref="sCrudApproval" :rowDefault="false">

        <template v-slot:SecStatus="{ row }">
            <v-icon small class="mr-2" @click="viewDetail(row)">
                {{ iconmobil }}
            </v-icon>
        </template>
        <template v-if="!this.isMobile" v-slot:options>
            <v-tooltip>
                <template v-slot:activator="{ on }">
                    <v-btn text v-on="on" :disabled="selected.length == 0" small elevation="0" @click="AddMasive(true)">
                        <v-icon style="font-size: 16px" color="success">far fa-thumbs-up</v-icon>
                    </v-btn>
                </template>
                <span>Aprobacion Masiva</span>
            </v-tooltip>
            <v-tooltip>
                <template v-slot:activator="{ on }">
                    <v-btn text v-on="on" :disabled="selected.length == 0" small elevation="0" @click="DenyMasive(true)">
                        <v-icon style="font-size: 16px" color="error">far fa-thumbs-down</v-icon>
                    </v-btn>
                </template>
                <span>Desaprobar Masivo</span>
            </v-tooltip>
        </template>

        <template v-slot:OrdTotal="{ row }">
            {{ row.OrdTotal >= 1000 ? parseFloat(row.OrdTotal.toFixed(2)).toLocaleString("es-MX") : row.OrdTotal.toFixed(2) }}
        </template>
    </s-crud>

    <v-col style="padding: 0px; margin-top: 6px" v-if="!this.isMobile">
        <s-toolbar :label="cabecera"></s-toolbar>

        <v-data-table hide-default-footer dense :items-per-page="-1" :items="itemsDetail" :headers="headersOrder">
            <template v-slot:item.OddAmount="{ item }">
                {{ item.OddAmount >= 1000 ? parseFloat(item.OddAmount.toFixed(2)).toLocaleString("es-MX") : item.OddAmount.toFixed(2) }}
            </template>
            <template v-slot:item.OddUnitPrice="{ item }">
                {{ item.OddUnitPrice >= 1000 ? parseFloat(item.OddUnitPrice.toFixed(2)).toLocaleString("es-MX") : item.OddUnitPrice.toFixed(2) }}
            </template>
        </v-data-table>
    </v-col>

    <v-dialog persistent v-model="dialogDelete" max-width="500px">
        <v-card>
            <v-card-title class="headline">Esta seguro de Rechazar?</v-card-title>
            <v-card-text>
                <s-textarea autofocus label="Motivo de Rechazo" v-model="AtsObservation" />
            </v-card-text>
            <v-card-actions>
                <v-btn color="red darken-1" text @click="closeDelete">Cancelar</v-btn>
                <v-spacer></v-spacer>
                <v-btn color="blue darken-1" dark @click="denyItemConfirm()">confirmar</v-btn>
            </v-card-actions>
        </v-card>
    </v-dialog>

    <v-dialog v-model="dialogViewOrder" persistent max-width="600px">
        <v-card>
            <v-toolbar>
                <v-toolbar-title>Orden</v-toolbar-title>
                <v-spacer></v-spacer>
                <v-btn icon @click="dialogViewOrder = false">
                    <v-icon>mdi-close</v-icon>
                </v-btn>
            </v-toolbar>
            <v-list>
                <v-list-item>
                    <v-list-item-avatar>
                        <v-icon class="grey lighten-1" dark> far fa-clipboard </v-icon>
                    </v-list-item-avatar>
                    <v-list-item-content>
                        <v-list-item-title v-text="this.detail.OrdDocumentNumber"></v-list-item-title>
                        <v-list-item-subtitle>N°Orden</v-list-item-subtitle>
                    </v-list-item-content>
                </v-list-item>
                <v-divider inset></v-divider>
                <v-list-item>
                    <v-list-item-avatar>
                        <v-icon class="grey lighten-1" dark> far fa-calendar-alt </v-icon>
                    </v-list-item-avatar>

                    <v-list-item-content>
                        <v-list-item-title v-text="$fun.formatDateView(this.detail.SecCreate)"></v-list-item-title>

                        <v-list-item-subtitle>F.Creacion</v-list-item-subtitle>
                    </v-list-item-content>
                </v-list-item>
                <v-list-item>
                    <v-list-item-avatar v-if="this.detail.TypeCurrency == 2">
                        <v-icon class="grey lighten-1" dark> fas fa-dollar-sign </v-icon>
                    </v-list-item-avatar>
                    <v-list-item-avatar v-else>
                        <h2>S/</h2>
                    </v-list-item-avatar>

                    <v-list-item-content>
                        <v-list-item-title>
                            <!-- {{this.detail.OrdTotal}} -->
                            {{
                  this.detail.OrdTotal >= 1000
                    ? parseFloat(this.detail.OrdTotal.toFixed(2)).toLocaleString("es-MX")
                    : this.detail.OrdTotal
                    ? this.detail.OrdTotal.toFixed(2)
                    : this.detail.OrdTotal
                }}
                        </v-list-item-title>

                        <v-list-item-subtitle>Monto Total</v-list-item-subtitle>
                    </v-list-item-content>
                </v-list-item>
                <v-divider></v-divider>
            </v-list>

            <v-list three-line>
                <v-subheader style="height: 6px; font-weight: bold; margin-bottom: 3px">Listado de Items</v-subheader>
                <v-list-item-group v-model="selected">
                    <template v-for="(item, index) in this.detail.Detail">
                        <v-list-item :key="index" style="min-height: 0.5px">
                            <template>
                                <v-row>
                                    <v-col cols="6" class="pr-0 mr-0 pr-0">
                                        <v-list-item-action style="font-size: 11px" class="mr-0 pr-0 text--primary">
                                            {{ item.OddDescription }}
                                        </v-list-item-action>
                                    </v-col>
                                    <v-col cols="6" class="pl-1 pr-0" style="align-self: center">
                                        <div style="font-size: 11px" class="pr-0">
                                            <v-row>
                                                <v-col cols="1" class="pr-0 pl-3">{{ item.UnitMeasurement }}</v-col>
                                                <v-col cols="4" class="pl-0 pr-0" style="align-self: center; text-align: right">
                                                    {{ item.OddQuantity.toFixed(2) }}
                                                </v-col>
                                                <v-col cols="6" class="pr-0 pl-0" style="align-self: center; text-align: right">{{
                            item.OddAmount >= 1000 ? parseFloat(item.OddAmount.toFixed(2)).toLocaleString("es-MX") : item.OddAmount.toFixed(2)
                          }}</v-col>
                                            </v-row>
                                        </div>
                                    </v-col>
                                </v-row>
                            </template>
                        </v-list-item>

                        <v-divider v-if="index < items.length - 1" :key="index" style="height: 1px"></v-divider>
                    </template>
                </v-list-item-group>
            </v-list>
            <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn color="error" dark @click="denyItem($event)" style="width: 90px">Desaprobar</v-btn>
                <v-btn color="primary" dark @click="approvalMobil($event)" style="width: 90px">APROBAR</v-btn>

                <v-spacer></v-spacer>
            </v-card-actions>
        </v-card>
    </v-dialog>

    <v-dialog v-model="processing" :persistent="messageProcessing.length == 0" width="400">
        <v-card color="primary" dark>
            <v-card-text v-if="messageProcessing.length == 0">
                Por favor espere
                <v-progress-linear indeterminate color="white" class="mb-0"></v-progress-linear>
            </v-card-text>
            <v-card-text v-else>
                <div class="text--white pt-4">
                    Error al realizar busqueda <br />
                    {{ messageProcessing }}
                </div>
            </v-card-text>
        </v-card>
    </v-dialog>
</div>
</template>

<script>
//Service
import _sApproval from "@/services/Logistics/OrderPurchaseApproval";
import _sOrder from "@/services/Logistics/OrderPurchaseService";
import _sNotification from "@/services/Logistics/ApprovalNotification";

export default {
    components: {

    },
    props: {},
    data() {
        return {
            
            processing: false,
            messageProcessing: "",
            filter: {
                UsrID: 0,
                PstID: 0
            },
            dialogDelete: false,
            dialogViewOrder: false,
            selected: {},
            OrdID: 0,
            items: [],
            requeriment: [],
            detail: [],
            AtsObservation: "",
            itemsDetail: [],
            cabecera: "Detalle",
            headersOrder: [{
                    text: "Descripcion",
                    value: "OddDescription"
                },

                {
                    text: "Cantidad",
                    value: "OddQuantity",
                    sortable: true,
                    align: "center"
                },
                {
                    text: "Unid. Medida",
                    value: "UnitMeasurement",
                    sortable: true
                },
                /* {
                    text: "Proveedor",
                    value: "CardName",
                    sortable: true,
                    width: 280
                }, */
                {
                    text: "Moneda",
                    value: "TypeCurrencyName",
                    sortable: false
                },
                {
                    text: "Precio Unitario",
                    value: "OddUnitPrice",
                    sortable: false,
                    align: "center"
                },
                {
                    text: "Total",
                    value: "OddAmount",
                    sortable: false,
                    align: "end"
                },
            ],
            isMasive: false,
            denyitem: {},
            requerimentMobil: {},
            isDeny: false,
            denyMobil: {},
            iconmobil: "far fa-envelope",

        }
    },

    created() {
        this.filter.UsrID = this.$fun.getUserID();
        this.filter.PstID = this.$fun.getUserInfo().CtrContract.PstID;
    },

    computed: {
        configCrud() {
            return {
                model: {
                    OrdID: "ID",
                    OrdDate: "date",
                    AleStatus: "int",
                    AtsStatus: "",
                    OrdTotal: "",
                    SecCreate: "date",
                    OrdAttach1: "",
                    OrdAttach2: "",

                    SecStatus: "int",
                },
                service: _sApproval,
                headers: this.isMobile ? [{
                        text: "",
                        value: "SecStatus",
                        align: "center",
                        width: "5"
                    },
                    {
                        text: "N°Orden",
                        value: "OrdDocumentNumber"
                    },
                    {
                        text: "Fecha",
                        value: "OrdDate"
                    },
                    {
                        text: "Proveedor",
                        value: "SupName"
                    },
                    {
                        text: "Usuario",
                        value: "NtpFullName"
                    },
                ] : [{
                        text: "N°Orden",
                        value: "OrdDocumentNumber",
                        width: "130"
                    },
                    {
                        text: "Fecha",
                        value: "OrdDate",
                        width: "100"
                    },
                    {
                        text: "Proveedor",
                        value: "SupName"
                    },
                    {
                        text: "Moneda",
                        value: "TypeCurrencyName"
                    },
                    {
                        text: "Total",
                        value: "OrdTotal",
                        align: "end"
                    },
                    {
                        text: "Usuario",
                        value: "NtpFullName",
                        width: "180"
                    },
                ],
            };
        },
        isMobile() {
            return this.$vuetify.breakpoint.xs;
        },
    },

    methods: {

        //Al Seleccionar Fila de Grilla
        rowSelected(item) {
            this.selected = item;

            console.log(this.selected)

            if (item.length > 0) {
                this.OrdID = item[item.length - 1].OrdID;
            } else {

                this.OrdID = 0;
                this.itemsDetail = [];
                this.cabecera = "Detalle";
            }

            if (this.OrdID != 0) this.getdataGeneral(this.OrdID);

        },

        //
        closeDelete() {
            this.dialogDelete = false;
            this.$nextTick(() => {
                this.editedItem = Object.assign({}, this.defaultItem);
                this.editedIndex = -1;
            });
        },

        //Ver Detalle de Orden
        getdataGeneral(id) {

            this.requeriment = {
                OrdID: id
            };
            //Orden de Compra

            _sOrder.byID(this.requeriment, this.$fun.getUserID()).then((r) => {
                if (r.status == 200) {
                    this.detail = {
                        ...r.data
                    };
                    this.itemsDetail = this.detail.Detail;

                    this.cabecera = "Detalle Orden " + this.detail.OrdDocumentNumber;
                }
            });
        },

        //********************************************** Desaprobar Orden ***************************************************************
        DenyMasive(item) {
            this.AtsObservation = "";
            this.dialogDelete = true;
            this.isMasive = true;
        },

        denyItem(item) {
            if (item != null) {
                this.dialogDelete = true;
                this.isMasive = false;
                this.AtsObservation = "";
                this.denyitem = item;
                this.isDeny = this.isMobile;
            }
        },

        denyItemConfirm(item) {
            if (this.isMasive) {
                this.selected.forEach((element) => {
                    element.DocumentNumber = element.OrdDocumentNumber;
                    element.DocEntry = element.OrdID;
                    element.TypeApproval = 2;

                    element.UsrCreateID = this.$fun.getUserID();
                    element.AtsStatus = 3;
                    element.AtsObservation = this.AtsObservation;
                });
                this.save(this.selected, 2);
            } else {
                if (this.isDeny) {
                    this.denyMobil.DocumentNumber = this.denyMobil.OrdDocumentNumber;
                    this.denyMobil.DocEntry = this.denyMobil.OrdID;
                    this.denyMobil.TypeApproval = 2;
                    this.denyMobil.AtsStatus = 3;

                    this.denyMobil.UsrCreateID = this.$fun.getUserID();
                    this.denyMobil.AtsObservation = this.AtsObservation;
                    this.items.push(this.denyMobil);
                } else {
                    this.denyitem.DocumentNumber = this.denyitem.OrdDocumentNumber;
                    this.denyitem.DocEntry = this.denyitem.OrdID;
                    this.denyitem.TypeApproval = 2;
                    this.denyitem.AtsStatus = 3;

                    this.denyitem.UsrCreateID = this.$fun.getUserID();
                    this.denyitem.AtsObservation = this.AtsObservation;
                    this.items.push(this.denyitem);
                }
                this.save(this.items, 2);
            }
            this.closeDelete();
        },

        //********************************************** Fin Desaprobar Orden ***************************************************************

        //********************************************** Aprobar Orden ***************************************************************
        AddMasive(item) {
            this.selected.forEach((element) => {
                element.AtsStatus = 2;
                element.DocumentNumber = element.OrdDocumentNumber;
                element.DocEntry = element.OrdID;
                element.TypeApproval = 2;
                element.UsrCreateID = this.$fun.getUserID();
            });
            this.save(this.selected, 1);
        },

        approvalMobil() {
            this.items = [];
            this.requerimentMobil.DocumentNumber = this.requerimentMobil.OrdDocumentNumber;
            this.requerimentMobil.DocEntry = this.requerimentMobil.OrdID;
            this.requerimentMobil.TypeApproval = 2;
            this.requerimentMobil.AtsStatus = 2;
            this.items.push(this.requerimentMobil);
            this.save(this.items, 1);
        },

        //********************************************** Fin Aprobar Orden ***************************************************************

        refresh() {
            this.items = [];
            this.dialogViewOrder = false;
            this.$refs.sCrudApproval.refresh();
        },

        save(item, transaction) {
            
            this.$fun.alert("Confirmar Operación?", "question").then((r) => {
                if (r.value) {
                    this.messageProcessing = ""
                    this.processing = true;
                    var message;
                    if (transaction == 1) {
                        message = "Guardado Correctamente";
                    } else {
                        message = "Actualizado Correctamente";
                    }
                    _sNotification.save(item, this.$fun.getUserID()).then((r) => {
                            this.processing = false;
                            this.$fun.alert(message, "success");
                            this.refresh();
                        },
                        (e) => {
                            this.messageProcessing = e.response.data.Message;
                            this.processing = true;
                        });
                }
            });
        },

        /*********************************** MODO MOVIL ******************************* */
        getdata(id) {

            this.dialogViewRequeriment = false;
            this.dialogViewOrder = true;

            this.requeriment = {
                OrdID: id
            };

            _sOrder.byID(this.requeriment, this.$fun.getUserID()).then((r) => {
                if (r.status == 200) {
                    this.detail = {
                        ...r.data
                    };
                }
            });

        },

        viewDetail(item) {
            this.requerimentMobil = item;
            this.iconmobil = "far fa-envelope-open";
            this.isDeny = true;
            this.denyMobil = item;

            this.getdata(item.OrdID);
        },
    }

};
</script>
